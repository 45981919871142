import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import Pickr from '@simonwep/pickr';

import PriorityEntity from "../../../priority/entity";
import Utils from "../../../../utils";
import * as bootstrap from 'bootstrap'
import DefaultController from "../../../defaultController";

export default class SettingsTasksPriority extends DefaultController {
    private element: any;
    private datatable: any;
    private editModal: any;
    entity = 'priorities'
    private colorPickers: any[] | undefined;

    async init() {
        this.element = jQuery(".datatables-priority")
        this.editModal = new bootstrap.Modal((document.querySelector("#editSettingsTicketPriorityModal") as HTMLElement));
        this.loadTable();
        this.colorPickers = []
        await super.init();
    }

    getEntityData(elem: any) {
        return PriorityEntity.getEntityData(elem)
    }

    bindListeners() {
        (document.querySelectorAll("form") as NodeListOf<HTMLFormElement>).forEach((form) => {
            (form.querySelectorAll("input[name='priority[color_picker]']") as NodeListOf<HTMLFormElement>).forEach((input) => {

                const pickr = Pickr.create({
                    el: input,
                    theme: 'classic',
                    default: 'rgba(0, 0, 0, 1)',
                    swatches: [
                        'rgba(102, 108, 232, 1)',
                        'rgba(40, 208, 148, 1)',
                        'rgba(255, 73, 97, 1)',
                        'rgba(255, 145, 73, 1)',
                        'rgba(30, 159, 242, 1)'
                    ],
                    components: {
                        preview: true,
                        opacity: false,
                        hue: true,
                        interaction: {
                            hex: false,
                            rgba: false,
                            hsla: false,
                            hsva: false,
                            cmyk: false,
                            input: false,
                            clear: false,
                            save: true
                        }
                    }
                })
                pickr.on("save", (e: any) => {
                    console.log(e, input, form)
                    const idField = form.querySelector("#priority_color") as HTMLInputElement
                    idField.value = e.toHEXA().toString()
                    pickr.hide();
                })
                // @ts-ignore
                this.colorPickers.push(pickr);
            });
        });

        (document.querySelector("#addNewPriorityForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewPriorityForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddPriority") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    elem.reset();
                    this.toastr.success(`${Utils.translate('priority.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
        this.element.delegate(".delete-record", "click", async (e: any) => {
            e.preventDefault();const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('priority.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('priority.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        this.element.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editSettingsTicketPriorityForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                if (this.colorPickers) {
                    this.colorPickers.forEach((picker: any) => {
                        picker.setColor(data.color)
                    })
                }
                this.editModal.show();
            }
        });
        (document.querySelector("#editSettingsTicketPriorityForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editSettingsTicketPriorityForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('priority.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })

    }

    loadTable() {
        this.datatable = this.element.DataTable({
            initComplete: () => {
                this.element.closest(".card").find(".loading-body").addClass("d-none")
                this.element.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/priorities`,
            columns: [
                {data: 'name'},
                {data: 'color'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display: block;width: 100%;height: 20px;background-color:${data}"></span>`;
                    },
                },
                {
                    targets: 2,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddPriority'
                    }
                }
            ]
        });
    }
}